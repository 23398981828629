import UIkit from 'uikit'

export default {
  init() {
    const btn_go_to_lesson = document.getElementById('go_to_lesson')
    btn_go_to_lesson &&
      btn_go_to_lesson.addEventListener('click', (event) => {
        event.preventDefault()
        const lesson_tabs = document.getElementById('lesson_tabs')
        UIkit.tab(lesson_tabs).show(1)
      })

    const btn_go_to_challenge = document.getElementById('go_to_challenge')
    btn_go_to_challenge &&
      btn_go_to_challenge.addEventListener('click', (event) => {
        event.preventDefault()
        const lesson_tabs = document.getElementById('lesson_tabs')
        UIkit.tab(lesson_tabs).show(2)
      })

    const btn_go_to_kit = document.getElementById('go_to_kit')
    btn_go_to_kit &&
      btn_go_to_kit.addEventListener('click', (event) => {
        event.preventDefault()
        const lesson_tabs = document.getElementById('lesson_tabs')
        UIkit.tab(lesson_tabs).show(1)
      })

    //THINKIFIC
    jQuery(document).ready(function ($) {
      const currentCourseID = document.getElementById('courseId').value

      $('.buy_form input[name=products]').bind('change', function () {
        const val = $(this).val()
        let $cdInput = $('#cartadocente [value="' + val + '"]')
        $('#cartadocente .course_product_item').hide()
        $cdInput.prop('checked', true)
        $cdInput.parent().show()
        $('.buy_btn').attr('href', val)
      })

      $.ajax({
        type: 'GET',
        dataType: 'json',
        url: ajax_login_object.ajaxurl,
        data: {
          action: 'get_user_enrollments', //calls wp_ajax_get_user_enrollments
        },
        success: function (data) {
          const courses = data?.courses || []

          const course = courses.find(
            (course) =>
              course.course_id.toString() === currentCourseID.toString()
          )

          $('.thinkific_block').removeClass('loading')

          if (course) {
            course.expired ? setCourseExpired() : setCourseActive(course)
          } else {
            setCourseBuy()
          }
        },
        error: function (error) {
          console.error('error', error)
          showError()
        },
        complete: function () {
          console.log('complete')
        },
      })

      function setCourseActive(course) {
        $('.locked_tab').removeClass('locked_tab')
        $('.locked_item').remove()
        $('#tBlockActive').fadeIn()
        setCourseProgress(course)
      }

      function setCourseExpired() {
        $('#tExpiredBuy').fadeIn()
      }

      function setCourseBuy() {
        $('#tBlockBuy').fadeIn()
      }

      function setCourseProgress(course) {
        let perc = Math.floor(course.percentage_completed * 100)
        if (perc < 100) {
          $('#tProgress').fadeIn()
          if (perc > 0) {
            $('#tProgressHalf').show()
            $('#progressPerc').text(perc)
          } else {
            $('#tProgress0').show()
          }
        } else {
          $('#tProgressFull').fadeIn()
        }
      }

      function showError() {
        $('#tBlockError').fadeIn()
        $('.thinkific_block').removeClass('loading')
      }

      function block_form(block, button, message, product) {
        button.disabled = block
        let cursor_style = 'default'
        message.classList.add('uk-text-danger')
        if (block) {
          button.classList.remove('button-primary')
          button.classList.add('button-default')
          message.classList.remove('uk-text-danger')
          message.innerHTML = `Stai acquistando <b>${product}</b>. Attendere...`
          cursor_style = 'wait'
        } else {
          button.classList.remove('button-primary')
          button.classList.add('button-primary')
          message.innerHTML = ''
        }
        document.body.style.cursor = cursor_style
      }

      // Get all the forms with class .carta_del_docente_form
      const cdd_forms = document.querySelectorAll('.carta_del_docente_form')

      const cdd_modal = document.getElementById('cartadocente-modal')

      // For each form, add an event listener for the submit event
      cdd_forms.forEach((el) =>
        el.addEventListener('submit', (ev) => {
          ev.preventDefault()

          const form = ev.target
          const submit_button = form.querySelector('.carta_del_docente_submit')
          let error_msg = form.querySelector('.carta_del_docente_error_item')
          const voucher = form.querySelector('.carta_del_docente_voucher').value
          const product = form.querySelector('.carta_del_docente_product').value
          const courseLink = form.querySelector(
            '.carta_del_docente_course_link'
          ).value

          if (voucher === '') {
            error_msg.classList.add('uk-text-danger')
            error_msg.innerHTML = 'Il campo del voucher è obbligatorio'
            return
          }

          block_form(true, submit_button, error_msg, product)

          // Get the Thinkific user and then consume the voucher
          fetch(`${ajax_login_object.ajaxurl}?action=get_thinkific_user`)
            .then((response) => response.json())
            .then((data) => {
              const Thinkific = {
                current_user: data,
              }

              fetch(
                'https://cartadeldocente.dev.dataninja.it/consume/' +
                  voucher +
                  '?' +
                  new URLSearchParams({
                    user_id: Thinkific.current_user.id,
                    user_email: Thinkific.current_user.email,
                    user_first_name: Thinkific.current_user.first_name,
                    user_full_name: Thinkific.current_user.full_name,
                    product_name: product,
                  })
              )
                .then((response) => {
                  if (response.ok) {
                    block_form(false, submit_button, error_msg, '')
                    form.querySelector('.carta_del_docente_success').innerHTML =
                      'Congratulazioni è andato tutto bene! Puoi iniziare il <a href="' +
                      courseLink +
                      '" class="thinkific_sso">corso</a>.'

                    // Reload page when modal is closed
                    cdd_modal.addEventListener('hidden', function () {
                      location.reload()
                    })
                    return response.json()
                  } else {
                    return Promise.reject(response)
                  }
                })
                .catch((response) => {
                  block_form(false, submit_button, error_msg, '')
                  if (typeof response.json !== 'undefined') {
                    response.json().then((error) => {
                      error_msg.innerHTML = error.detail
                    })
                  } else {
                    error_msg.innerHTML =
                      "C'è stato un errore di sistema, si prega di contattarci scrivendoci a school@dataninja.it"
                  }
                })
            })
        })
      )
    })
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
}
